import { BrowserModule } from "@angular/platform-browser";
import { isDevMode, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./components/app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { WebsocketService } from "./services/websocket.service";
import { DisconnectedComponent } from "./components/disconnected/disconnected.component";
import { Connect4GameFieldComponent } from "./components/games/connect4/game-field/connect4-game-field.component";
import { JoinGameComponent } from "./components/menu/private-lobby-join/join-game.component";
import { PostGameLobbyMenuComponent } from "./components/menu/post-game-lobby/post-game-lobby-menu.component";
import { MatCardModule } from "@angular/material/card";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { QueueMenuComponent } from "./components/menu/queue-menu/queue-menu.component";
import { PrivateLobbyMenuComponent } from "./components/menu/private-lobby-menu/private-lobby-menu.component";
import { MainMenuComponent } from "./components/menu/main-menu/main-menu.component";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { HeaderComponent } from "./components/layout/header/header.component";
import { FooterComponent } from "./components/layout/footer/footer.component";
import { Connect4Component } from "./components/games/connect4/connect4.component";
import { HomeComponent } from "./components/home/home.component";
import { MillComponent } from "./components/games/mill/mill.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { QrCodeModule } from "ng-qrcode";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MenuComponent } from "./components/menu/menu/menu.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { from, Observable } from "rxjs";
import { TicTacToeComponent } from "./components/games/ticTacToe/ticTacToe.component";
import { TicTacToeGameFieldComponent } from "./components/games/ticTacToe/tic-tac-toe-game-field/tic-tac-toe-game-field.component";
import { LanguageSwitchComponent } from "./components/menu/language-switch/language-switch.component";
import { AppDownloadLinksComponent } from "./components/app-download-links/app-download-links.component";
import { UserService } from "./services/user.service";
import { FeatureFlagService } from "./services/feature-flag.service";
import { LoginComponent } from "./components/login/login.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { gameReducer } from "./+state/game/game.reducer";
import { settingsReducer } from "./+state/settings/settings.reducer";
import { routerReducer, StoreRouterConnectingModule } from "@ngrx/router-store";
import { GameEffects } from "./+state/game/game.effects";
import { EffectsModule } from "@ngrx/effects";
import { SettingsEffects } from "./+state/settings/settings.effects";
import { AdsenseModule } from "ng2-adsense";

export class WebpackTranslateLoader implements TranslateLoader {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

@NgModule({
  declarations: [
    AppComponent,
    DisconnectedComponent,
    AppComponent,
    Connect4GameFieldComponent,
    PostGameLobbyMenuComponent,
    JoinGameComponent,
    QueueMenuComponent,
    PrivateLobbyMenuComponent,
    MainMenuComponent,
    JoinGameComponent,
    HeaderComponent,
    FooterComponent,
    Connect4Component,
    HomeComponent,
    MillComponent,
    MenuComponent,
    TicTacToeComponent,
    TicTacToeGameFieldComponent,
    LanguageSwitchComponent,
    AppDownloadLinksComponent,
    LoginComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AdsenseModule.forRoot({
      adClient: "ca-pub-8403667218281580",
      adSlot: 6790344836,
    }),
    AppRoutingModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    OverlayModule,
    QrCodeModule,
    ClipboardModule,
    ScrollingModule,
    MatSnackBarModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      registrationStrategy: "registerWhenStable:30000",
    }),
    StoreModule.forRoot({ game: gameReducer, settings: settingsReducer, router: routerReducer }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production, connectInZone: true }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([GameEffects, SettingsEffects]),
  ],
  providers: [WebsocketService, Location, UserService, FeatureFlagService, provideHttpClient(withInterceptorsFromDi())],
})
export class AppModule {}
